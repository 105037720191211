import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingRight: '8px',
  },
});

export default styles;